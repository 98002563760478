import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@mui/material';
import { Question as QuestionType } from './index.types';
import {
  StyledContainer,
  StyledQuestionTitle,
  StyledQuestionDescription,
  StyledMoreInformation,
  StyledQuestionSubtitle,
  StyledQuestionTitleWrapper,
} from './index.styles';
import QuestionAnswer from '../QuestionAnswer';
import { QuestionnaireContext } from '../Data/QuestionnaireContext';
import ShowMoreButton from '../../../../components/ShowMoreButton';
import IconAndSectionTitle from './IconAndSectionTitle';
import _ from 'lodash';
import EsgExportToolsButton from 'src/components/EsgExportToolsButton';
import { useAppSelector } from 'src/redux-file/hooks';
import { StyledFlex } from 'src/components/StyledFlex';
import { QuestionReferences } from 'src/views/Pages/ESG/forms/references';

type Props = {
  payload: QuestionType;
};

export default function Question(props: Props) {
  const {
    id: questionId,
    key: questionKey,
    title,
    description,
    field_set: fields,
    subtitle,
    questionanswers: questionAnswers,
    allow_many: allowMany,
    section_type_key,
    questionnaire,
  } = props.payload;

  // const noAnswers =
  //   Object.values(questionAnswers || []).filter(
  //     (a) => Object.values(a.record).length !== 0
  //   ).length === 0;

  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  const report = useAppSelector((state) => state.esg.activeReport);

  const { questionnaireAnswerId, setQuestionnaireAnswerState } =
    useContext(QuestionnaireContext);

  const handleAddNewRecord = () => {
    setQuestionnaireAnswerState((prevState) => {
      // Escaping undefined state
      if (!prevState) return prevState;
      const newId = `temp-id-${Date.now()}`;

      // copy newState from prevState
      const newState = _.cloneDeep(prevState);

      // create questionAnswer if does not exist
      const newQuestionAnswers =
        newState.questionnaire.questions[questionKey].questionanswers || {};

      // find the last ordered index
      const maxOrder = Math.max(
        ...Object.values(newQuestionAnswers).map((x) => x.order),
        -1
      );

      // assign newQuestionAnswer to newQuestionAnswers
      newQuestionAnswers[newId] = {
        id: newId,
        question: questionId,
        questionnaire_answer: String(questionnaireAnswerId),
        record: Object.fromEntries(fields.map((f) => [f.key, null])),
        order: maxOrder + 1,
      };

      // assign newQuestionAnswers to newState
      newState.questionnaire.questions[questionKey].questionanswers =
        newQuestionAnswers;

      return newState;
    });
  };

  const showAddButton = allowMany;

  const orderedQuestionAnswerList = useMemo(
    () =>
      Object.values(questionAnswers || {}).sort((a, b) => a.order - b.order),
    [questionAnswers]
  );

  return (
    <>
      {section_type_key && (
        <StyledFlex sx={{ mt: '40px', mb: '16px' }}>
          <IconAndSectionTitle sectionTypeKey={section_type_key} />
          {report && section_type_key === 'questions' && (
            <EsgExportToolsButton
              reportIds={[report.id]}
              questionnaireIds={[questionnaire]}
              size="medium"
            />
          )}
        </StyledFlex>
      )}
      <StyledContainer>
        <StyledQuestionTitleWrapper>
          <StyledQuestionTitle>{title}</StyledQuestionTitle>
          {!!subtitle && (
            <StyledQuestionSubtitle>{subtitle}</StyledQuestionSubtitle>
          )}
          <QuestionReferences questionKey={questionId} />
        </StyledQuestionTitleWrapper>

        {/* existing records */}
        {orderedQuestionAnswerList.map((questionAnswer) => (
          <QuestionAnswer
            key={`question-${questionId}-questionanswer-${questionAnswer.id}`}
            questionAnswer={questionAnswer}
            fields={fields}
            question={props.payload}
          />
        ))}

        {!!description && (
          <>
            {show && (
              <StyledMoreInformation>
                {t('questionnaireV3.question.moreInformation')}
              </StyledMoreInformation>
            )}
            <Collapse in={show} timeout="auto" unmountOnExit>
              <StyledQuestionDescription
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Collapse>

            <ShowMoreButton show={show} setShow={setShow} />
          </>
        )}

        {/* new record */}
        <br />
        {showAddButton && (
          <Button
            className="Esg EsgSecondary"
            sx={{ ml: 2.5, width: 400 }}
            variant="outlined"
            color="warning"
            startIcon="+"
            onClick={handleAddNewRecord}
          >
            {t('questionnaireV3.question.addNewRecordButton')}
          </Button>
        )}
      </StyledContainer>
    </>
  );
}
