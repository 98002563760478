import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import styled from 'styled-components';
import themeColors from '../../../theme/colors';

/* eslint-disable no-unused-vars */

export const MethodBoxWrapper = styled.div`
  // display: grid;
  // grid-column-gap: 24px;
  // grid-template-columns: 1fr 1fr;
`;

export const StyledMethodBox = styled.div`
  position: relative;
  background: ${themeColors.pureWhite};
  border: 1px solid ${themeColors.strokePressed};
  border-radius: 16px;
  padding: 24px 22px 22px;

  p {
    padding-bottom: 0;
  }
`;

export const Title = styled.p`
  color: ${themeColors.greenBrand};
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const Based = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 12px;
`;

export const Desc = styled(
  ({
    active,
    ...props
  }: { active: boolean } & HTMLAttributes<HTMLParagraphElement>) => (
    <p {...props} />
  )
)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 24px;
  color: ${({ active }) =>
    active ? themeColors.dark : themeColors.grayInactive};
`;

export const PointWrapper = styled(
  ({
    active,
    ...props
  }: { active: boolean } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  color: ${({ active }) =>
    active ? themeColors.greenBrand : themeColors.grayInactive};
  display: flex;
  margin-bottom: 12px;
  align-items: center;

  svg {
    min-width: 24px;
    max-width: 24px;
    margin-right: 12px;
  }
`;

export const PointText = styled(
  ({
    active,
    ...props
  }: { active: boolean } & HTMLAttributes<HTMLParagraphElement>) => (
    <p {...props} />
  )
)`
  color: ${({ active }) =>
    active ? themeColors.dark : themeColors.grayInactive};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

export const Button = styled(
  ({
    active,
    ...props
  }: { active: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button type="button" {...props} />
  )
)`
  padding: 0 18px 0 12px;
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  color: ${({ active }) =>
    active ? themeColors.pureWhite : themeColors.grayInactive};
  font-weight: 600;
  font-size: 18px;
  border: 1px solid
    ${({ active }) =>
      active ? themeColors.greenBrand : themeColors.strokePressed};
  background: ${({ active }) =>
    active ? themeColors.greenBrand : themeColors.pureWhite};
  margin: 0px 12px 12px 0px;
  //not-allowed
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};

  svg {
    margin-right: 10px;
  }
`;
