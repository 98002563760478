import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IReference {
  text: string | null;
  link_label: string | null;
  name: string | null;
  number: string | null;
  section: string | null;
  subsection: string | null;
  paragraph: string | null;
  subparagraph: string | null;
  clause: string | null;
  related_ar: string | null;
  reference_type: string | null;
  mandatory_type: string | null;
  datapoint_id: string | null;
  uri: string | null;
}

export interface IReferencesContext {
  questions: { [key: string]: IReference[] };
  fields: { [key: string]: IReference[] };
}

export const ReferecesContext = React.createContext<IReferencesContext>({
  questions: {},
  fields: {},
});

function Reference(props: { reference: IReference }) {
  const { reference } = props;
  const header = [
    reference.name,
    reference.number,
    reference.section,
    reference.subsection,
    reference.paragraph,
    reference.subparagraph,
    reference.clause,
  ]
    .filter((x) => !!x)
    .join(', ');
  return (
    <>
      <Typography variant="captionText" fontWeight="bold" component="span">
        {header}
        {header && reference.related_ar && ', '}
        {reference.related_ar && (
          <Box component="span" sx={{ color: 'primary.main' }}>
            {reference.related_ar}
          </Box>
        )}
        {(header || reference.related_ar) && reference.text && ': '}
      </Typography>
      {reference.text && (
        <Typography
          variant="captionText"
          component="span"
        >{`"${reference.text}" `}</Typography>
      )}
      {reference.uri && (
        <Link variant="captionText" href={reference.uri} target="_blank">
          (link)
        </Link>
      )}
    </>
  );
}

function References(props: { references: IReference[] }) {
  const { references } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.common',
  });
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(true);
  if ((references?.length ?? 0) === 0) return null;
  return (
    <Box
      sx={{
        fontStyle: 'italic',
        color: '#505050',
        border: '1px solid #D4E3FB',
        borderBottom: '3px solid #D4E3FB',
        borderRadius: '4px',
        background: '#F2F6FC',
        padding: '8px 16px',
        marginBottom: '32px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="captionText">{t('references')}:</Typography>
        <IconButton onClick={() => setIsCollapseOpen((x) => !x)}>
          <KeyboardArrowDown />
        </IconButton>
      </div>
      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        <List dense>
          {references.map((reference, index) => (
            <ListItem key={index}>
              <ListItemText>
                <Reference reference={reference} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
}

// eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
export function QuestionReferences(props: { questionKey: string }) {
  return null; // we dont have these for now
  /*
  const referencesContext: IReferecesContext =
    React.useContext(ReferecesContext);
  const references = referencesContext?.questions[props.questionKey] || [];
  return <References references={references} />;
  */
}

export function FieldReferences(props: { fieldKey: string }) {
  const referencesContext: IReferencesContext =
    React.useContext(ReferecesContext);
  const references = referencesContext?.fields[props.fieldKey] || [];
  return <References references={references} />;
}
